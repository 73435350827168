import { MutableRefObject, useEffect } from 'react';
import HLS from 'hls.js';

export interface UseHLSProps {
  videoRef: MutableRefObject<HTMLVideoElement>;
  src: string;
  autoPlay?: boolean;
  component?: React.ReactNode;
}
export const useHLS = ({ videoRef, src, autoPlay }: UseHLSProps) => {
  useEffect(() => {
    if (HLS.isSupported()) {
      const hls = new HLS({
        startLevel: 3,
      });
      hls.loadSource(src);
      hls.attachMedia(videoRef.current);
      hls.on(HLS.Events.MANIFEST_PARSED, () => {
        if (autoPlay) {
          videoRef.current.play();
        }
      });

      return () => {
        hls.destroy();
      };
    } else if (videoRef.current.canPlayType('application/vnd.apple.mpegurl')) {
      // Hack for iPhones lol
      const onMetadata = () => {
        if (autoPlay) {
          videoRef.current.play();
        }
      };
      videoRef.current.src = src;
      videoRef.current.addEventListener('loadedmetadata', onMetadata);
    }
  }, [autoPlay, src, videoRef]);
};
