import React, {
  DetailedHTMLProps,
  FC,
  useRef,
  VideoHTMLAttributes,
} from 'react';
import { CSSProperties } from 'styled-components';
import { useHLS } from '~/hooks/use-hls';

export interface PlayerProps {
  src: string;
  autoPlay?: boolean;
  className?: string;
  style?: CSSProperties;
}
export const Player: FC<
  PlayerProps &
    DetailedHTMLProps<VideoHTMLAttributes<HTMLVideoElement>, HTMLVideoElement>
> = ({ src, autoPlay, ...videoProps }) => {
  const videoRef = useRef<HTMLVideoElement>();
  useHLS({
    src,
    videoRef,
    autoPlay,
  });

  return <video ref={videoRef} {...videoProps} />;
};
