import styled from 'styled-components';
import { up } from 'styled-breakpoints';
import { Player } from '~/components/Player';

export const Center = styled.div`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 40vh;

  ${up('sm')} {
    width: 60vh;
  }
`;

export const VideoContainer = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
`;

export const StyledPlayer = styled(Player)`
  min-width: 100%;
  min-height: 100%;
  width: auto !important;
  height: auto !important;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;
