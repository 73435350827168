import React, { FC } from 'react';
import Div100vh from 'react-div-100vh';
import { ReactSVG } from 'react-svg';
import { Center, VideoContainer, StyledPlayer } from './styles';

export const Layout: FC = () => {
  return (
    <>
      <VideoContainer>
        <StyledPlayer
          autoPlay
          playsInline
          muted
          loop
          src="/static/hls/playlist.m3u8"
          preload="auto"
        />
      </VideoContainer>
      <Div100vh>
        <Center>
          <ReactSVG src="/static/hitbox-logo.svg" />
        </Center>
      </Div100vh>
    </>
  );
};

export default Layout;
